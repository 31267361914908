
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { getPanel } from "@/api/encounter.api";

export interface Data {
  f: any;
}
export default defineComponent({
  name: "Settings Page",
  components: {},
  props: [],
  setup(props) {
    let organizationId = ref<string | null>("");
    const router = useRouter();
    let data = reactive<Data>({
      f: "",
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Settings");
      // route to default view
      //router.replace("/Settings/providersPage");
    });

    return {
      organizationId,
      data,
    };
  },
});
